import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guides.scss';
import { Button, Card, Table } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { GFLCharacter } from '../../../modules/exilium/components/exilium-character';

const ExiliumGuidesPlat: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page exilium-page gfl-guide'}
      game="exilium"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/gfl-exilium/">Girls' Frontline 2: Exilium</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/gfl-exilium/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Platoon Explained</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/exilium/categories/category_guild.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Platoon Explained</h1>
          <h2>
            All you need to know about Platoon System in Girls' Frontline 2:
            Exilium.
          </h2>
          <p>
            Last updated: <strong>25/12/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Disclaimer" />
        <p>
          This guide was created by <strong>Snows</strong> who is a Guild Leader
          for a Top 1% Platoon on the CN Server and a guidemaker/theorycrafter.
          For any feedback you can contact him on Discord - here's his handle:
          swonsavon.
        </p>
        <p>
          This guide focuses only on explaining the Platoon system coming to the
          Global server soon, but Snows also{' '}
          <strong>
            created a dedicated guide for those who want to be competitive in
            this mode
          </strong>{' '}
          and you can find it under this link (check the Gunsmoke Frontline
          tab):
        </p>
        <OutboundLink
          href="https://docs.google.com/document/d/1JVkcqWUFeyf-ralVKTD742K4WmKNleUJf7IYuDxQnJ4/edit?tab=t.9wvnomg7a81w#heading=h.jt1u0vwnq1we"
          target="_blank"
        >
          <Button variant="primary">Snows Platoon Guide</Button>
        </OutboundLink>
        <SectionHeader title="Recruiting and its importance" />
        <StaticImage
          src="../../../images/exilium/generic/guild_1.webp"
          alt="Platoon Explained"
        />
        <p>
          Before you begin you should ask yourself what Platoon you are trying
          to be and what members you are looking for - whether it's Casual,
          Semi-Competitive (get all Rewards), or Competitive (Ranking). Due to
          the slow leveling you are very limited to the amount of people in
          Guild/Platoon to start and as you progress. Who you recruit- and
          making sure that they are all active - is important.
        </p>
        <ul>
          <li>
            <strong>Casual</strong>: Don’t really care about rankings and just
            want a Guild for the Guild Shop and Daily Crystal.
          </li>
          <li>
            <strong>Semi-Competitive (get all Rewards)</strong>: Getting 5% in
            Gunsmoke for all Rewards.
          </li>
          <li>
            <strong>Competitive (ranking)</strong>: Getting all rewards asap and
            vanity; Flexing your Guild Ranking (Top 1 - 100) and leveling as
            fast as possible in order to unlock shop items.
          </li>
        </ul>
        <h5>Picking the right members</h5>
        <p>
          Guild EXP requires daily task completion + you can borrow Support
          units of members in your Guild so it’s pretty crucial the quality of
          people you recruit. So in terms of people you want to recruit it’d be
          like this:
        </p>
        <ul>
          <li>
            <strong>Casual</strong>: Whoever you want, ideally daily active.
          </li>
          <li>
            <strong>Semi-Competitive (get all Rewards)</strong>: Daily Active,
            having one or two+ consistent V6 players to borrow Supports from.
          </li>
          <li>
            <strong>Competitive (Ranking)</strong>: As many V6 players as
            possible - who are willing to spend daily crystal for Dustsmoke (if
            you are aiming for levels + Ranking).
          </li>
        </ul>
        <p>
          <strong>For 5% and up</strong> it’s very very crucial you have that V6
          owners to borrow Supports from - due to the advantage you’d have over
          95% of other guilds. This is a borderline requirement in CN for 5%,
          although it will depend on the overall level of EN Platoons +
          population of course. Having consistent V6 (and R6 does help) of the
          important units for every Gunsmoke Season is something to keep in
          mind.
        </p>
        <p>Here are the most wanted V6 characters:</p>
        <div className="employees-container-card-mode-gfl">
          <Card className="avatar-card">
            <GFLCharacter slug="qiongjiu" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="klukai" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter slug="makiatto" mode="card" enablePopover showLabel />
          </Card>
          <Card className="avatar-card">
            <GFLCharacter
              slug="springfield"
              mode="card"
              enablePopover
              showLabel
            />
          </Card>
        </div>
        <p>
          Others important characters:{' '}
          <strong>
            Invested Papasha (as much as possible), Zhaohui V5/V6, Peritya V6,
            Vector V6, Andoris V6, Belka V6.
          </strong>
        </p>
        <h5>Roles and Moderation</h5>
        <StaticImage
          src="../../../images/exilium/generic/guild_3.webp"
          alt="Platoon Explained"
        />
        <p>In this tab here as Guild Leader you can check your members list.</p>
        <p>
          The columns from left to right are:{' '}
          <strong>
            Name - Role - Weekly Merit Points - Total Merit Points - Status
          </strong>
        </p>
        <p>
          <strong>Roles</strong>
        </p>
        <ul>
          <li>
            Guild Leader: Has control over the Guilds Info (Name, Tags, Titles),
            can accept Guild Applications, can promote/demote/kick/transfer
            Guild Leadership, can disband the Guild,
          </li>
          <li>Vice-Leaders: Can accept Guild Applications.</li>
        </ul>
        <p>
          <strong>Merit Points</strong>
        </p>
        <p>
          Merit Points are not EXP, it is some arbitrary number that is gained
          based on a couple of things:
        </p>
        <ul>
          <li>Logging in (50)</li>
          <li>Daily Patrol Completed (40)</li>
          <li>Gunsmoke Hits (???)</li>
        </ul>
        <p>
          Note: As a Guild Leader for Daily Patrol and Gunsmoke there is no way
          of knowing besides Merit Points and Gunsmoke Points to tell if someone
          is doing their daily activities. You can use this as a SCUFFED method
          in order to track this - mostly Daily Patrol during non Gunsmoke as
          we're not sure how much merit you accumulate exactly from Gunsmoke.
          The best way is naturally recruiting people with a good mindset and
          activity rate.
        </p>
        <SectionHeader title="All about leveling" />
        <StaticImage
          src="../../../images/exilium/generic/guild_4.webp"
          alt="Platoon Explained"
        />
        <h5>Slots and Levels</h5>
        <p>
          Platoons start with 24 slots available at Level 1 - then increases by
          +2/+1 as you level. The amount of Platoon EXP needed is shown below,
          as well as the levels + caps:
        </p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Level</th>
              <th>Experience Cost</th>
              <th>Member Count</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>-</td>
              <td>24</td>
            </tr>
            <tr>
              <td>2</td>
              <td>2400</td>
              <td>26</td>
            </tr>
            <tr>
              <td>3</td>
              <td>7920</td>
              <td>28</td>
            </tr>
            <tr>
              <td>4</td>
              <td>20400</td>
              <td>30</td>
            </tr>
            <tr>
              <td>5</td>
              <td>40560</td>
              <td>32</td>
            </tr>
            <tr>
              <td>6</td>
              <td>76560</td>
              <td>34</td>
            </tr>
            <tr>
              <td>7</td>
              <td>153360</td>
              <td>36</td>
            </tr>
            <tr>
              <td>8</td>
              <td>316650</td>
              <td>38</td>
            </tr>
            <tr>
              <td>9</td>
              <td>489360</td>
              <td>39</td>
            </tr>
            <tr>
              <td>10</td>
              <td>854160</td>
              <td>40</td>
            </tr>
            <tr>
              <td>11</td>
              <td>982160</td>
              <td>41</td>
            </tr>
            <tr>
              <td>12</td>
              <td>1178960</td>
              <td>42</td>
            </tr>
            <tr>
              <td>13</td>
              <td>1380560</td>
              <td>43</td>
            </tr>
            <tr>
              <td>14</td>
              <td>1586960</td>
              <td>44</td>
            </tr>
            <tr>
              <td>15</td>
              <td>1798160</td>
              <td>45</td>
            </tr>
            <tr>
              <td>16</td>
              <td>2122160</td>
              <td>46</td>
            </tr>
            <tr>
              <td>17</td>
              <td>2453360</td>
              <td>47</td>
            </tr>
            <tr>
              <td>18</td>
              <td>2791760</td>
              <td>48</td>
            </tr>
            <tr>
              <td>19</td>
              <td>3252560</td>
              <td>49</td>
            </tr>
            <tr>
              <td>20</td>
              <td>3722960</td>
              <td>50</td>
            </tr>
          </tbody>
        </Table>
        <p>
          There are two ways to gain Platoon EXP: Daily Patrol + Gunsmoke
          Frontline.
        </p>
        <h5>Daily Patrol</h5>
        <StaticImage
          src="../../../images/exilium/generic/guild_5.webp"
          alt="Platoon Explained"
        />
        <p>
          This gives 40 Platoon EXP. Daily Mission for each member that is
          always recurring - gives Platoon Coins + Crystal. It's essentially
          just a mobbing map, not difficult at all.
        </p>
        <p>
          Do note as a Guild Leader you cannot tell who has completed their
          Daily Patrol - there is no way to track this in-game.
        </p>
        <h5>Gunsmoke Frontline</h5>
        <StaticImage
          src="../../../images/exilium/generic/guild_6.webp"
          alt="Platoon Explained"
        />
        <p>
          This gives 30 Platoon EXP per ticket used. You get 2x tickets per day
          - you can also buy an additional 1 Ticket for 50 Crystals.
        </p>
        <h5>Leveling takes a long time</h5>
        <p>
          CN Top 3 Guilds have just hit Level 8 - doing 3 hits a day with all
          members after almost an year's worth of progress (as on 22-Dec-2024).
        </p>
        <p>
          Leveling requirement costs are absurd and{' '}
          <strong>Level 20 itself will take YEARS</strong> to reach, so keep
          that in mind when planning and reserving members. If you are a big
          community you should be making multiple guilds and not expecting to
          depend on level ups.
        </p>
        <h5>Leveling is not just for slots</h5>
        <p>
          As you might have noticed there are 2 level milestones between each
          Level - these give certain benefits to Guild:
        </p>
        <ul>
          <li>Unlocking iItems in Guild Shop,</li>
          <li>Upgrading the Daily Guild Chest,</li>
          <li>Buffs in Gunsmoke Frontline.</li>
        </ul>
        <p>
          So you aren't just leveling just for slots but also for these 3
          benefits. Guilds who make sure to be active daily (and buy tickets) to
          reach these milestones have a distinct advantage over other guilds,
          especially in regardless to slots + buffs for Gunsmoke - as it will
          massively boost your rankings.
        </p>
        <p>
          These Milestones are only present from Levels 1 - 10. Past Level 10
          there are no more Milestones, only levels for slots.
        </p>
        <SectionHeader title="Guild Rewards" />
        <StaticImage
          src="../../../images/exilium/generic/guild_7.webp"
          alt="Platoon Explained"
        />
        <h5>Platoon Coins</h5>
        <p>
          Platoon Currency is the currency you gain from this mode and spend in
          the shop.
        </p>
        <p>You gain these from:</p>
        <ul>
          <li>Daily Chest,</li>
          <li>Daily Patrol,</li>
          <li>Daily Patrol Chests,</li>
          <li>Gunsmoke Frontline Runs,</li>
          <li>Level Up Milestones.</li>
        </ul>
        <p>You spend these in: </p>
        <ul>
          <li>Weekly Reset Shop</li>
          <li>Permanent (One-Time Shop)</li>
        </ul>
        <h5>Daily Patrol</h5>
        <StaticImage
          src="../../../images/exilium/generic/guild_8.webp"
          alt="Platoon Explained"
        />
        <h5>Chests</h5>
        <StaticImage
          src="../../../images/exilium/generic/guild_9.webp"
          alt="Platoon Explained"
        />
        <h5>Daily Login Chest</h5>
        <ul>
          <li>You get one of these daily with a 24 HR Timer.</li>
          <li>RNG Rewards.</li>
          <li>This Chest also upgrades based on Level Milestones.</li>
        </ul>
        <StaticImage
          src="../../../images/exilium/generic/guild_10.webp"
          alt="Platoon Explained"
        />
        <h5>Daily Patrol Chest</h5>
        <ul>
          <li>
            There is a chance for these to drop whenever a member completes
            Daily Patrol - it drops for the whole Guild. It is important for
            everyone to do Daily Patrol to get the most chances at chests.
          </li>
          <li>RNG Rewards.</li>
          <li>This Chest does not upgrade.</li>
          <li>You can check in “Updates” to see who dropped a Chest.</li>
        </ul>
        <StaticImage
          src="../../../images/exilium/generic/guild_11.webp"
          alt="Platoon Explained"
        />
        <SectionHeader title="Shops" />
        <StaticImage
          src="../../../images/exilium/generic/guild_13.webp"
          alt="Platoon Explained"
        />
        <h5>Weekly Shop</h5>
        <ul>
          <li>
            Where you obtain additional Papasha Dupes (via Fragments), 5 per
            week.
          </li>
          <li>
            Other items are very much not recommended due to the small amount of
            coins you get, you cannot buy out this shop weekly.
          </li>
          <li>
            If you have excess and don’t need to spend anything in Perma shop
            you can buy in priority:{' '}
            <strong>Papasha Fragments &gt; Gold</strong> &gt; EXPS &gt; Uncaps.
            Never buy Attachment Box.
          </li>
        </ul>
        <StaticImage
          src="../../../images/exilium/generic/guild_14.webp"
          alt="Platoon Explained"
        />
        <h5>Permanent Shop</h5>
        <ul>
          <li>
            <strong>One-Time Purchase Items</strong> - once you buy them out
            they are GONE.{' '}
          </li>
          <li>Items here are unlocked based on Level Milestones.</li>
          <li>You can get Papashas SSR gun and an item to Refine it. </li>
          <li>There are also Cores + Tickets.</li>
          <li>
            Do note like stated with Level Milestones can take an extremely long
            time to unlock these items.
          </li>
        </ul>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default ExiliumGuidesPlat;

export const Head: React.FC = () => (
  <Seo
    title="Platoon Explained | Girls' Frontline 2: Exilium | Prydwen Institute"
    description="All you need to know about Platoon System in Girls' Frontline 2: Exilium."
    game="exilium"
  />
);
